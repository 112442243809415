import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Drop from '../components/NewDropdown'

export const BookPageTemplate = ({ title }) => {
  return (
    <Layout>
      <section
        style={{ minHeight: '85vh' }}
        className="px-2 py-4 bg-light-grey sm:px-16"
      >
        <div className="flex justify-center">
          <div>
            <h2 className="mb-8 font-bold text-center">{title}</h2>

            <h4 className="mb-4">
              Volumes of Praise for a Vanishing God (2015)
            </h4>

            <a href="https://www.lulu.com/search?q=Volumes+of+Praise+for+a+Vanishing+God&adult_audience_rating=00">
              <img
                alt="Volumes of Praise for a Vanishing God (2015)"
                className="float-left w-32 mx-8 sm:mr-4 sm:pb-4"
                src="/img/book.jpg"
              />
            </a>

            <p>
              This book seeks to provide a biblical foundation for answering
              questions regarding congregational singing. From Jesus' statement
              in John 4:24, "God is spirit and they that worship Him must
              worship Him in spirit and truth," the author derives Definitions
              and Principles for worship. These then guide the discussion on the
              numerous, important musical issues that the church faces today
              with respect to congregational singing. The discussions make
              significant use of fascinating, recent scholarship in musicology
              and the psychology of music.
            </p>

            <p className="my-2"></p>

            <p>
              You can purchase the book (at a modest discount) by clicking on
              the book cover, to the left; it can also be purchased at{' '}
              <a href="https://www.amazon.com/s?k=volumes+of+praise+for+a+vanishing+god&i=stripbooks&dc&ref=a9_sc_1">
                amazon
              </a>
              . An electronic version is also available at vendor.
            </p>

            <h4 className="my-4 mt-32">
              Worship and Congregational Singing (2016)
            </h4>

            <a href="https://www.lulu.com/search?adult_audience_rating=00&q=Worship%20and%20Congregational%20Singing">
              <img
                alt="Worship and Congregational Singing (2016)"
                className="float-left w-32 mx-8 sm:mr-4 md:pb-4"
                src="/img/book2.png"
              />
            </a>

            <p>
              This work includes most of the material in "Volumes of Praise for
              a Vanishing God," but it considerably broader in scope. It
              includes a deeper discussion of the worship Principles, with
              applications to congregational singing and the songs which are
              sung. Standards are given by which to evaluate song texts, music,
              and instrumental accompaniment. The book contains full
              documentation and an abundance of endnotes following each chapter.
              Many of these endnotes pursue topics of interest that are
              mentioned only briefly in the text proper. Each chapter ends with
              a brief list of questions to spur further study and discussion. It
              is hoped that the book will be useful as a text for a seminary, or
              undergraduate, course on congregational singing, a course that the
              author believers to be a great need for the church of the
              twenty-first century.
            </p>

            <p className="my-2"></p>

            <p>
              You can purchase the book (at a modest discount) by clicking on
              the book cover, to the left; it can also be purchased at{' '}
              <a href="https://www.amazon.com/Worship-Congregational-Singing-David-Neu/dp/136501018X/ref=sr_1_2?crid=7ELE9LZM39MA&dchild=1&keywords=worship+and+congregational+singing&qid=1593808606&s=books&sprefix=Worship+and+Congregational+%2Caps%2C180&sr=1-2">
                amazon
              </a>
              .
            </p>

            <p className="my-2"></p>

            <ul className="mt-16 text-left">
              <li>
                To hear musical selections mentioned in either book click{' '}
                <a href="/book-selection">here</a>; to download all of the
                musical examples click{' '}
                <Drop noBorder header="here">
                  <div className="flex flex-col">
                    <a href="music-examples/VP/Zipped/Hymntunes.zip" download>
                      Volumes of Praise for a Vanishing God
                    </a>
                    <a
                      className="mt-3"
                      href="music-examples/WCS/Zipped/Hymntunes.zip"
                      download
                    >
                      Worship and Congregational Singing
                    </a>
                  </div>
                </Drop>
                .
              </li>

              <li>
                Free bookmarks containing the Definitions and Principles are
                available upon request. Please email your name, address, and
                book title to{' '}
                <a href="mailto:bookmark@NeuSong.com">bookmark@NeuSong.com</a>.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

BookPageTemplate.propTypes = {
  props: PropTypes.object,
  shown: PropTypes.string,
  chapter: PropTypes.string,
  path: PropTypes.string,
  book: PropTypes.string,
  data: PropTypes.object,
  title: PropTypes.string,
}

const BookPage = ({ data }) => {
  const { markdownRemark: post } = data

  return <BookPageTemplate title={post.frontmatter.title} data={data} />
}

BookPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BookPage

export const aboutPageQuery = graphql`
  query BookPage($id: String!) {
    allNamesJson(sort: { fields: name, order: ASC }) {
      nodes {
        chapter
        id
        name
        relativePath
        relativeDirectory
        book
        url {
          publicURL
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
